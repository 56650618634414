<template>
  <div id="app" class="relative min-h-screen flex flex-col items-center bg-gray-900 text-white">
    <div id="particles-js" class="absolute top-0 left-0 w-full h-full z-0"></div>
    <header
      class="relative w-full bg-cover bg-center py-16 text-center"
      style="z-index: 1">
      <h1 class="text-5xl font-bold">FilmEngine.ai</h1>
      <p class="mt-4 text-xl">A premium domain for AI-driven film production solutions</p>
    </header>

    <main class="relative flex-grow w-full max-w-5xl mx-auto px-6 py-12 space-y-12" style="z-index: 1">
      <section class="bg-gray-800 p-8 rounded-lg shadow-lg">
        <h2 class="text-3xl font-semibold">Domain for Sale</h2>
        <p class="mt-4 text-gray-300">
          The premium domain <strong>FilmEngine.ai</strong> is now available for purchase. This domain is perfect for
          businesses in the film production, AI technology, or entertainment industries. Don't miss this opportunity to
          own a valuable and memorable domain name.
        </p>
      </section>

      <section class="bg-cover bg-center p-8 rounded-lg shadow-lg">
        <h2 class="text-3xl font-semibold">Make an Offer</h2>
        <p class="mt-4 text-gray-200">Interested in acquiring this domain? Enter your offer below:</p>
        <form @submit.prevent="submitOffer" class="mt-6 flex flex-col space-y-4">
          <input
            v-model="email"
            type="email"
            placeholder="Enter your email"
            class="p-4 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            required />
          <input
            v-model="offer"
            type="number"
            placeholder="Enter your offer amount in USD"
            class="p-4 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            required />
          <button
            type="submit"
            class="p-4 bg-gradient-to-r from-purple-500 to-blue-500 rounded-md hover:from-purple-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-purple-500 flex justify-center items-center"
            :disabled="loading">
            <span v-if="!loading">Submit Offer</span>
            <span v-else class="flex flex-row">
              <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.372 0 0 5.372 0 12h4z"></path>
              </svg>
              Processing...
            </span>
          </button>
        </form>
      </section>
    </main>

    <footer class="w-full py-6 bg-gray-800">
      <div class="flex justify-center space-x-8">
        <p class="text-gray-300"><strong>Email:</strong> amithpintu@gmail.com</p>
        <p class="text-gray-300"><strong>Phone:</strong> +919481447790</p>
      </div>
      <p class="mt-4 text-gray-400 text-center">&copy; 2024 FilmEngine.ai</p>
    </footer>

    <div
      v-if="showModal"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 z-50">
      <div
        class="bg-white text-black p-8 rounded-lg shadow-lg w-2/6 mx-auto transform transition-transform duration-300 scale-95 opacity-0 animate-modal-fade-in">
        <h3 class="text-2xl font-semibold text-center">Offer Submitted</h3>
        <p class="mt-4 text-center">Thank you for your offer!</p>
        <div class="mt-4 bg-gray-200 p-4 rounded-lg">
          <p><strong>Email:</strong> {{ email }}</p>
          <p class="mt-2"><strong>Offer Amount:</strong> ${{ offer }}</p>
        </div>
        <button
          @click="closeModal"
          class="mt-6 w-full p-4 bg-gradient-to-r from-purple-500 to-blue-500 rounded-md hover:from-purple-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-purple-500">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import emailjs from "emailjs-com";
import 'particles.js/particles';
const particlesJS = window.particlesJS;

const email = ref(null);
const offer = ref(null);
const loading = ref(false);
const showModal = ref(false);

const submitOffer = () => {
  if (email.value && offer.value) {
    loading.value = true;
    emailjs
      .send(
        process.env.VUE_APP_EMAILJS_SERVICE_ID, // Use environment variable for service ID
        process.env.VUE_APP_EMAILJS_TEMPLATE_ID, // Use environment variable for template ID
        { email: email.value, offer: offer.value }, // Replace with the variable names from your template
        process.env.VUE_APP_EMAILJS_USER_ID // Use environment variable for user ID
      )
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        loading.value = false;
        showModal.value = true;
      })
      .catch((error) => {
        loading.value = false;
        alert("Failed to submit offer: " + error.text);
      });
  } else {
    alert("Please provide both your email and an offer amount.");
  }
};

const closeModal = () => {
  showModal.value = false;
  email.value = null;
  offer.value = null;
};

onMounted(() => {
  particlesJS.load("particles-js", "/particles-config.json", function () {
    console.log("particles.js loaded - callback");
  });
});
</script>

<style scoped>
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes modal-fade-in {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-modal-fade-in {
  animation: modal-fade-in 0.3s ease-out forwards;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
</style>